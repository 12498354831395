import React, {useEffect, useState} from "react";
import {Button, Tooltip, CircularProgress, Typography} from "@material-ui/core";
import { CustomDialog, CustomDatePicker, FormSuccessMessage } from "~components";
import SVG from "react-inlinesvg";
import { useFreeAccess, useUser } from "~store";
import moment from "moment";

const ActionFreeUpgrade = ({ row }) => {

  const [, userActions] = useUser();
  const [freeAccessState, freeAccessActions] = useFreeAccess();
  const [triggerClose, setTriggerClose] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [state, setState] = useState({
    oneMonth: false,
    lifetime: false,
    expired_at: null
  })

  useEffect(() => {
    if(isSuccess) {
      userActions.fetchUsers();
    }
  }, [isSuccess])

  const handleUpgrade = async () => {
    setIsSuccess(false);
    await freeAccessActions.save();
    setIsSuccess(true);
  }

  return(
    <CustomDialog
      title={"Free Upgrade"}
      maxWidth={"sm"}
      triggerCloseButton={triggerClose}
      triggerButton={
        <Tooltip arrow title={"Free Upgrade"}>
          <button className="btn btn-icon btn-light btn-hover-warning btn-sm">
            <span className="svg-icon svg-icon-md svg-icon-warning">
              <SVG src={"/media/svg/icons/Files/Upload.svg"} />
            </span>
          </button>
        </Tooltip>
      }
      actionButtons={
        <Button
          className={"btn btn-primary btn-elevate px-3"}
          onClick={() => handleUpgrade()} disabled={freeAccessState.loading}
          disabled={freeAccessState.freeAccess.expired_at === null && !freeAccessState.freeAccess.life_time}
        >
          {freeAccessState.loading ? <>Saving... <CircularProgress size={14} className={`ml-2`} color={`inherit`} /> </>: "Upgrade"}
        </Button>
      }
    >
      <UpgradeForm
        row={row}
        isSuccess={isSuccess}
        setIsSuccess={setIsSuccess}
        state={state}
        setState={setState}
      />
    </CustomDialog>
  )
}

const UpgradeForm = ({ row, isSuccess, setIsSuccess, state, setState }) => {

  const [freeAccessState, freeAccessActions] = useFreeAccess();

  useEffect(() => {
    if(row.free_access) {
      let access = row.free_access;
      access.life_time = row.free_access !== null && (row.free_access.expired_at === null || row.free_access.expired_at === "");
      freeAccessActions.setState({ freeAccess: access });
    }
    return () => {
      setIsSuccess(false);
      freeAccessActions.resetFreeAccess();
    }
  }, []);

  const handleChange = (key, value) => {
    setState({
      ...state,
      [key]: value
    })
    freeAccessActions.setState({
      freeAccess: {
        ...freeAccessState.freeAccess,
        "user_id": row.id,
        expired_at: key === 'life_time' && value ? null : freeAccessState.freeAccess.expired_at,
        [key]: value
      }
    });
  }

  const handleSelectOneMonth = (event) => {

    let checked = event.target.checked;
    setState({
      ...state,
      oneMonth: checked
    })

    if(checked) {
      let created_at = freeAccessState.freeAccess.id !== "" ? freeAccessState.freeAccess.created_at : moment().format("YYYY-MM-DD");
      let expired_at = event.target.checked ? freeAccessActions.addMonth(created_at, 1) : null;

      freeAccessActions.setState({
        freeAccess: {
          ...freeAccessState.freeAccess,
          user_id: row.id,
          expired_at: expired_at,
          life_time: false
        }
      });
    }
  }


  if(row.free_access && freeAccessState.freeAccess.id === "") {
    return <></>
  }

  return(
    <div>
      {isSuccess && <FormSuccessMessage message={`Account has been upgraded.`} />}
      <div className={"d-flex flex-column"}>
        <div className={`mb-4`}>
          <Typography variant={"body1"}>
            Grant a free upgrade to <b>{row.fname} {row.lname}</b> ({row.email})
          </Typography>
          {freeAccessState.freeAccess.id !== "" && (
            <div className={`mt-1`}>
              <Typography variant={"caption"}>
                This user was granted a free upgrade starting from
                <span className={`font-weight-bold text-primary`}>
                  {" " + moment(freeAccessState.freeAccess.created_at).format("MM/DD/YYYY")}.
                </span>
              </Typography>
            </div>
          )}
        </div>
        <div className="checkbox-list mb-2">
          <label className="checkbox">
            <input type="checkbox" checked={state.oneMonth} onChange={(event) => handleSelectOneMonth(event)} />
            <span></span>
            One month free access
          </label>
        </div>
        <div className="checkbox-list mb-2">
          <label className="checkbox">
            <input type="checkbox" checked={state.life_time}
             onChange={(event) => handleChange("life_time", event.target.checked)}
            />
            <span></span>
            Lifetime free access
          </label>
        </div>
        <Typography variant={"body1"}>
          Custom:
        </Typography>
        <CustomDatePicker
          date={state.expired_at ? new Date(state.expired_at) : null}
          label={"Expiry date:"}
          disabled={state.life_time}
          handleChange={(date) => handleChange("expired_at", date)}
        />
      </div>
    </div>
  )
}

export default ActionFreeUpgrade;
