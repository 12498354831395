import React from "react";
import { NavLink, useHistory } from "react-router-dom";
import SVG from "react-inlinesvg";
import {Tooltip} from "@material-ui/core";
import { useReviewResume } from "~store";

const ActionsColumnFormatter = (
  cellContent,
  row
) => {
  return (
    <div>
      <Tooltip arrow title={"View"}>
        <NavLink
          to={`/review-resume/view-resume/${row.resume.data.hasOwnProperty('user_type') ? row.resume.data.user_type : "professional"}/${row.id}`}
          className="btn btn-icon btn-light btn-hover-success btn-sm"
        >
          <span className="svg-icon svg-icon-md svg-icon-success">
            <SVG src={"/media/svg/icons/General/Search.svg"} />
          </span>
        </NavLink>
      </Tooltip>
      <ReviewResumeEdit row={row} />
    </div>
  );
}

const ReviewResumeEdit = ({ row }) => {

  const history = useHistory();
  const [, reviewResumeActions] = useReviewResume();

  const handleOnClick = async () => {
    await reviewResumeActions.updateStatus(row.id, "in_progress");
    let user_type = row.resume.data.hasOwnProperty('user_type') ? row.resume.data.user_type : "professional"
    history.push(`/review-resume/resume/${user_type}/${row.id}/${row.resume_ruid}`);
  }

  return(
    <Tooltip arrow title={"Review"}>
      <span onClick={() => handleOnClick()} className="btn btn-icon btn-light btn-hover-primary btn-sm mx-2 cursor-pointer">
        <span className="svg-icon svg-icon-md svg-icon-primary">
          <SVG src={"/media/svg/icons/Communication/Write.svg"} />
        </span>
      </span>
    </Tooltip>
  )
}

export default ActionsColumnFormatter;
