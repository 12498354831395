import React from "react";

const SubscriptionTypeColumnFormatter = (cellContent, row) => {
  let subscription = "FREE"
  if(row.free_access) subscription = "FREE UPGRADE"
  else if(row.subscriptions.length > 0) subscription = "PREMIUM"
  else if(row.download_credits > 0) subscription = "RESUME DOWNLOAD"
  return (
    <span
      className={`
        label label-lg font-weight-bold label-inline
        ${row.subscriptions.length > 0 || row.free_access ? "label-light-warning" : "label-light-primary"}`}
    >
      {subscription}
    </span>
  )
}

export default SubscriptionTypeColumnFormatter;
