import React from "react";
import globalHook from 'use-global-hook';
import axios from "axios";

const initialState = {
  data: {},
  options: {
    "job-title": []
  }
};

const actions = {
  setState: (store, newState) => {
      store.setState(newState)
  },
  fetchOptions: async (store, param = 'resume') => {
    try {
      let { data } = await axios.get(`/api/admin/pre-written-texts?editor=${param}`);
      let fieldOptions = {};
      let newData = {};

      for(let item of data) {
        newData[item['slug']] = item['options'];

        if(item['slug'] === "pre-written-responsibilities") {
            continue;
        }

        let options = [];
        for(let sItem of item['options']) {
            options.push(sItem.option)
        }

        fieldOptions[item['slug']] = options;

        if(item.slug === "job-title") {
            fieldOptions[item.slug] = fieldOptions[item.slug].sort((a,b)=> (a.job_title > b.job_title ? 1 : -1))
        }
      }

      store.setState({
        options: fieldOptions,
        data: newData
      });
    }
    catch(error) {
      console.log(error);
    }
  },
  setJobResponsibilities: (store, jobTitle) => {
    let jobResponsibilities = [];

    if(!store.state.data.hasOwnProperty('pre-written-responsibilities')) {
      return;
    }

    if(jobTitle !== "" && store.state.data.hasOwnProperty('job-title') && store.state.data['job-title'].length > 0) {
      let job = store.state.data['job-title'].filter(item => item.option === jobTitle);

      if(job.length > 0) {
        job = job[0];

        for(let item of store.state.data['pre-written-responsibilities']) {
          // let parent_ids = JSON.parse(item.parent_option_id);
          let parent_ids = item.parent_option_id;
          if(!Array.isArray(parent_ids)) {
            parent_ids = Object.values(parent_ids)
          }
          if(parent_ids.indexOf(job.id.toString()) !== -1) {
            jobResponsibilities.push(item.option);
          }
        }
      }
    }

    store.setState({
      options: {
        ...store.state.options,
        "pre-written-responsibilities": jobResponsibilities
      }
    });
  }
}

const usePreWrittenOptions = globalHook(React, initialState, actions)

export default usePreWrittenOptions;
