import React from "react";

const ResumeNameColumnFormatter = (
  cellContent,
  row
) => {
  let resume_name = row.resume_ruid
  resume_name = row.resume.data["job-title"] ?? resume_name
  resume_name = row.resume.data.resume_title ?? resume_name

  return (
    <span>
      {resume_name}
    </span>
  )
}

export default ResumeNameColumnFormatter;
