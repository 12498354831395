import React from 'react';
import { headerSortingClasses, sortCaret } from "~helpers";
import {
  DateCreatedColumnFormatter,
  CompanyLogoColumnFormatter,
  RoleColumnFormatter
} from "~pages/Jobs/components/column-formatters";
import globalHook from 'use-global-hook';

const columns = [
  {
    dataField: "count",
    text: "",
    sort: false,
    sortCaret: sortCaret,
    headerSortingClasses,
    headerClasses: "align-baseline"
  },
  {
    dataField: "company_logo",
    text: "",
    sort: false,
    formatter: CompanyLogoColumnFormatter,
    sortCaret: sortCaret,
    headerSortingClasses,
    headerClasses: "align-baseline min-w-75px"
  },
  {
    dataField: "company",
    text: "Company",
    sort: true,
    sortCaret: sortCaret,
    headerSortingClasses,
    headerClasses: "align-baseline"
  },
  {
    dataField: "job_title",
    text: "Job Title",
    sort: true,
    formatter: RoleColumnFormatter,
    sortCaret: sortCaret,
    headerSortingClasses,
    headerClasses: "align-baseline"
  },
  {
    dataField: "rate",
    text: "Rate",
    sort: true,
    sortCaret: sortCaret,
    headerSortingClasses,
    headerClasses: "align-baseline"
  },
  {
    dataField: "location",
    text: "Location",
    sort: true,
    sortCaret: sortCaret,
    headerSortingClasses,
    headerClasses: "align-baseline w-225px"
  },
  {
    dataField: "opportunity_type",
    text: "Opportunity Type",
    sort: true,
    sortCaret: sortCaret,
    headerSortingClasses,
    headerClasses: "align-baseline w-155px"
  },
  {
    dataField: "created_at",
    text: 'Date Created',
    sort: true,
    sortCaret: sortCaret,
    formatter: DateCreatedColumnFormatter,
    headerSortingClasses,
    headerClasses: "align-baseline w-155px"
  },
];

const initialState = {
  columns: columns,
  defaultSorted: [{ dataField: "posted_at", order: "asc" }],
  sizePerPageList: [
    { text: "10", value: 10 },
    { text: "20", value: 20 },
    { text: "50", value: 50 },
    { text: "100", value: 100 },
    { text: "500", value: 500 }
  ],
  queryParams: {
    sortOrder: "asc",
    sortField: "posted_at",
    pageNumber: 1,
    pageSize: 10,
  },
  filter: {
    searchText: "",
    website: "",
  },
};

const state = initialState;

const actions = {
  setState: (store, newState) => {
    store.setState(newState);
  },
};

const useJobList = globalHook(React, state, actions);

export default useJobList;
