import React from 'react';
import globalHook from 'use-global-hook';

const initialState = {
  jobs: [],
  jobTotal: 0,
};

const state = initialState;

const actions = {
  setState: (store, newState) => {
    store.setState(newState);
  },
  fetchAll: async (store, queryParams = {}, filters = {}) => {
    try {
      let params = `?page=${queryParams.pageNumber}
        &pageSize=${queryParams.pageSize}
        &sortField=${queryParams.sortField}
        &sortOrder=${queryParams.sortOrder}
        &searchText=${filters.searchText}
        &website=${filters.website !== "all" ? filters.website : ""}`;

      let { data } = await global.axios({
        method: "GET",
        url: `/api/admin/jobs${params}`
      });

      let count = (queryParams.pageSize*queryParams.pageNumber) - queryParams.pageSize + 1;
      let jobs = data.data
      for(let i in jobs) {
        jobs[i]['count'] = count++;
      }

      let state = {
        jobs: data.data,
        jobTotal: data.meta.total
      }
      store.setState(state)
    } catch (error) {
      console.log(error);
    }
  },
};

const useJob = globalHook(React, state, actions);

export default useJob;
