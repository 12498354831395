import React from "react";

const CompanyLogoColumnFormatter = (
    cellContent,
    row
) => {
  return (
    <div className="symbol symbol-40 symbol-sm flex-shrink-0 symbol-light-primary mr-2">
        {row.company_logo &&  <img className="" src={row.company_logo} alt="" />}
    </div>
  )
}

export default CompanyLogoColumnFormatter;
