import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ModalProgressBar } from "~partials/controls";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  IconButton
} from '@material-ui/core';
import { Close } from "@material-ui/icons";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const CustomDialog = ({
  // disableBackdropClick = true,
  fullWidth = true,
  maxWidth = "md",
  title = "",
  triggerButton = null,
  actionButtons = null,
  dividers = true,
  dialogActions = true,
  triggerCloseButton = false,
  showProgressBar = false,
  wrapperClassName = "",
  children,
  ...props
}) => {

  const [open, setOpen] = useState(false);
  const toggleModal = () => {
    setOpen(!open);
  }

  useEffect(() => {
    if(triggerCloseButton) {
      setOpen(false);
    }
  }, [triggerCloseButton])

  return (
    <span className={wrapperClassName}>
      <span id={"toggle-modal"} className={"cursor-pointer"} onClick={() => toggleModal()}>
       {triggerButton && triggerButton}
        {!triggerButton && (
          <Button onClick={() => toggleModal()} className={"btn btn-primary"}>
            Show Modal
          </Button>
        )}
      </span>
      <Dialog
        keepMounted
        open={open}
        TransitionComponent={Transition}
        // disableBackdropClick={disableBackdropClick}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        onClose={() => toggleModal()}
        {...props}
        style={{ zIndex: 9999 }}
      >
        {showProgressBar && <ModalProgressBar />}
        <DialogTitle>
          {title}
          <IconButton
            size={"small"}
            className={"position-absolute modal-close-btn"}
            onClick={() => toggleModal()}
          >
            <Close fontSize={"small"} />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers={dividers}>
          {open && children}
        </DialogContent>
        {dialogActions && (
          <DialogActions className={"px-8 py-4"}>
            {actionButtons && actionButtons}
            <Button onClick={() => toggleModal()} className={"btn btn-light btn-elevate"}>
              Close
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </span>
  );
}

CustomDialog.propTypes = {
  title: PropTypes.string,
  triggerButton: PropTypes.element,
  actionButtons: PropTypes.element,
  // disableBackdropClick: PropTypes.bool,
  fullWidth: PropTypes.bool,
  maxWidth: PropTypes.string,
  dividers: PropTypes.bool,
  dialogActions: PropTypes.bool,
  triggerCloseButton: PropTypes.bool,
  showProgressBar: PropTypes.bool,
  wrapperClassName: PropTypes.string,
  children: PropTypes.element.isRequired || PropTypes.string.isRequired
};


export default CustomDialog;
