import React from 'react';
import globalHook from 'use-global-hook';
import moment from "moment";

const initialState = {
  loading: false,
  options: []
};

const state = JSON.parse(JSON.stringify(initialState));

const actions = {
  setState: (store, newState) => {
    store.setState(newState);
  },
  fetchOptions: async (store, user_id) => {
    try {
      let { data } = await global.axios({
        method: "GET",
        url: `/api/admin/fetch-video-resumes?user_id=${user_id}`
      });

      if(data.length > 0) {
        let vOptions = [];
        for(let item of data) {
          let url = `${process.env.REACT_APP_MIX_VIDEO_RESUME_URL}/${item.slug}/`;
          url += item.title ?? "";
          let vOption = {
            label: url,
            value: url,
          }
          vOptions.push(vOption);
        }
        store.actions.setState({ options: vOptions });
      }
    }
    catch (error) {
      console.log(error);
    }
  },
};

const useVideoResume = globalHook(React, state, actions);

export default useVideoResume;
