import React from "react";
import { Link } from "react-router-dom";

const RoleColumnFormatter = (
        cellContent,
        row
) => {
  return (
    <Link target={"_blank"} to={{pathname: row.job_link}}>
      {row.job_title}
    </Link>
  )
}

export default RoleColumnFormatter;
