import React from "react";
import moment from "moment";

const DateCreatedColumnFormatter = (
        cellContent,
        row
) => {
  return (
    <span>
      {row.created_at ? moment(row.created_at).format("DD/MM/YYYY h:mm:ss") : ""}
    </span>
  )
}

export default DateCreatedColumnFormatter;
